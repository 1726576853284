// Translated
// Migrated
<template>
  <button
    class="btn btn-blue"
    type="submit"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <div
      v-if="loading"
      class="spinner-grow text-white"
    />
    <span v-else>
      <slot />
    </span>
  </button>
</template>

<script>
export default defineNuxtComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],
})
</script>

<style lang="scss">
.btn-submit-md {
  width: 100%;
  line-height: 1.45rem;

  .spinner-grow {
    width: 1rem;
    height: 1rem;
  }
}
</style>
